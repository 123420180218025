<template>
	<div class='h100'>
		<Navbar :showBack="true" :ifSetData="true" @updataAllData="updataAllData" backgroundColor="#FF9941" color="#fff">Life/Health Policy</Navbar>
		<div class="wrapper h90 OverflowYAuto">
			<!-- 表格 -->
			<ElementTable :configData="disabled ? configDataDis : configData" :tableData="existing_policies" height="400">
				<el-table-column slot="operation" label="Action" fixed="right">
					<template slot-scope="scope">
						<i class="el-icon-edit" @click="editDetails(scope.row)"></i>
						<i class="el-icon-delete ml20px" @click="deleteDetails(scope.row)"></i>
					</template>
				</el-table-column>
			</ElementTable>
			<!-- 其他资产 -->
			<Navbar backgroundColor="#e8e8e8" fontSize="18px" className="flex" v-if="!disabled">
				<img src="@/assets/img/add.png" alt="" @click="AddDetails" style="margin-left: 98%;">
			</Navbar>
			<!-- 编辑、新增弹窗 -->
			<ElementDialog Title="Life/Health Policy" :Visible.sync="Dialog.visible">
				<el-form slot="content" :model="InvestmentForm" ref="InvestmentForm" class="my-form">
					<el-row>
						<el-col :span="12">
							<el-form-item label="Insurance Company" prop="insurance_company">
								<el-input v-model="InvestmentForm.insurance_company" @input="InvestmentForm.insurance_company = $util.inputReg(InvestmentForm.insurance_company)"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Policy Type" prop="policy_type">
								<el-select v-model="InvestmentForm.policy_type" placeholder="">
									<el-option v-for="item in policytypeOptions" :key="item.code" :label="item.name" :value="item.code" />
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Year Of Issue" prop="year_of_issue">
								<el-input v-model="InvestmentForm.year_of_issue" type="number"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Year Of Maturity" prop="year_of_maturity">
								<el-input v-model="InvestmentForm.year_of_maturity" type="number"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Remarks" prop="remarks">
								<el-input v-model="InvestmentForm.remarks" @input="InvestmentForm.remarks = $util.inputReg(InvestmentForm.remarks)"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Annualised/Single Premium(S$)" prop="annualised_single_premium">
								<el-input v-model="InvestmentForm.annualised_single_premium" type="number"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Death(S$)" prop="death">
								<el-input v-model="InvestmentForm.death" type="number"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Total Permanent Disability(S$)" prop="total_permanent_disability">
								<el-input v-model="InvestmentForm.total_permanent_disability" type="number"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Critical Illness(S$)" prop="critical_illness">
								<el-input v-model="InvestmentForm.critical_illness" type="number"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Personal Accident(S$)" prop="personal_accident">
								<el-input v-model="InvestmentForm.personal_accident" type="number"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Hospitalisation Expenses(S$)" prop="hospitalisation_expenses">
								<el-input v-model="InvestmentForm.hospitalisation_expenses" type="number"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Daily Hospital Benefit(S$)" prop="daily_hospital_benefit">
								<el-input v-model="InvestmentForm.daily_hospital_benefit" type="number"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Long Term Care(S$)" prop="long_term_care">
								<el-input v-model="InvestmentForm.long_term_care" type="number"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Cash Value(S$)" prop="cash_value">
								<el-input v-model="InvestmentForm.cash_value" type="number"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Maturity Value(S$)" prop="maturity_value">
								<el-input v-model="InvestmentForm.maturity_value" type="number"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Clients Objectives" prop="clients_objectives">
								<el-input v-model="InvestmentForm.clients_objectives" @input="InvestmentForm.clients_objectives = $util.inputReg(InvestmentForm.clients_objectives)"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
				<div slot="BtnSolt" class="TextAlginCenter">
					<el-button type="warning" v-if="Dialog.status == 'Add'" @click="SaveAdd">Add</el-button>
					<el-button type="warning" v-if="Dialog.status == 'Edit'" @click="SaveUpdate">Update</el-button>
					<el-button type="warning" @click="Dialog.visible = false">Cancel</el-button>
				</div>
			</ElementDialog>
		</div>
	</div>
</template>

<script>
import ElementTable from '../../../../components/ElementTable.vue';
import Navbar from "../../../../components/Navbar.vue";
import ElementDialog from '../../../../components/ElementDialog/index.vue';

export default {
	components: { Navbar, ElementTable, ElementDialog },
	data () {
		return {
			existing_policies: [],
			// 弹窗form表单数据
			InvestmentForm: {
				annualised_single_premium: '',
				cash_value: '',
				clients_objectives: '',
				critical_illness: '',
				daily_hospital_benefit: '',
				death: '',
				existing_policies_id: Date.now().toString(),
				hospitalisation_expenses: '',
				insurance_company: '',
				long_term_care: '',
				maturity_value: '',
				personal_accident: '',
				policy_type: '',
				remarks: '',
				total_permanent_disability: '',
				year_of_issue: '',
				year_of_maturity: ''
			},
			policytypeOptions: global.typeOfPolicy,
			// Dialog弹窗
			Dialog: {
				visible: false,
				status: "Add",
			},
			disabled: false,
			kycAllData: {}
		};
	},
	computed: {
		configData () {
			return [
				{
					slot: "operation"
				}, {
					label: "Insurance Company/Name Of Plan",
					prop: "insurance_company",
					width: '300px'
				}, {
					label: "Policy Type",
					prop: "policy_type",
					width: '120px'
				}, {
					label: "Year Of Issue",
					prop: "year_of_issue",
					width: '130px'
				}, {
					label: "Year Of Maturity/Expiry",
					prop: "year_of_maturity",
					width: '280px'
				}, {
					label: "Remarks",
					prop: "remarks",
					width: '120px'
				}, {
					type: "amount",
					label: "Annualised/Single/Premium(AP/SP)",
					prop: "annualised_single_premium",
					width: '310px'
				}, {
					type: "amount",
					label: "Death",
					prop: "death"
				}, {
					type: "amount",
					label: "Total & Permanent Disability",
					prop: "total_permanent_disability",
					width: '300px'
				}, {
					type: "amount",
					label: "Critical Illness",
					prop: "critical_illness",
					width: '180px'
				}, {
					type: "amount",
					label: "Personal Accident",
					prop: "personal_accident",
					width: '200px'
				}, {
					type: "amount",
					label: "Hospitalisation Expenses",
					prop: "hospitalisation_expenses",
					width: '240px'
				}, {
					type: "amount",
					label: "Daily Hospital Benefit",
					prop: "daily_hospital_benefit",
					width: '230px'
				}, {
					type: "amount",
					label: "Long-term Care",
					prop: "long_term_care",
					width: '200px'
				}, {
					type: "amount",
					label: "Cash Value",
					prop: "cash_value",
					width: '150px'
				}, {
					type: "amount",
					label: "Maturity Value",
					prop: "maturity_value",
					width: '150px'
				}, {
					label: "Client’s Objectives",
					prop: "clients_objectives",
					width: '200px'
				}
			]
		},
		configDataDis () {
			let arr = this.configData
			arr.splice(0, 1)
			arr.splice(0, 0, {
				label: "Client’s Objectives",
				prop: "clients_objectives",
				width: '200px'
			})
			arr.splice(arr.length - 1, 1)
			return arr
		},
		kycClient () {
			return this.$store.state.KYC.kycClient
		}
	},
	watch: {},
	methods: {
		// 返回上一级页面时，将本页值赋kycalldate
		updataAllData () {
			if (!this.disabled) {
				this.kycAllData.kyc.client[this.kycClient].existing_investments_insurance.existing_policies = this.existing_policies
				this.$store.commit('SET_KYC_ALL_DATA', this.kycAllData)
			}
		},
		// 删除信息
		deleteDetails (row) {
			this.$confirm('confirm to delete?', 'Alert', {
				confirmButtonText: 'CONFIRM',
				cancelButtonText: 'CANCEL',
				type: "info",
			}).then(() => {
				// 删除逻辑
				this.existing_policies.map((temp, index) => {
					if (temp.existing_policies_id === row.existing_policies_id) {
						this.existing_policies.splice(index, 1)
					}
				})
			})
		},
		// 新增亲属信息
		AddDetails () {
			this.InvestmentForm = this.$ResetFrom(this.InvestmentForm)
			this.InvestmentForm.existing_policies_id = Date.now().toString()
			this.openDialog('Add')
		},
		// 编辑亲属信息
		editDetails (row) {
			this.InvestmentForm = JSON.parse(JSON.stringify(row))
			this.openDialog('Edit')
		},
		// 打开弹窗
		openDialog (status) {
			this.Dialog.visible = true
			this.Dialog.status = status
		},
		// 新增
		SaveAdd () {
			this.$refs.InvestmentForm.validate(valid => {
				if (valid) {
					this.existing_policies.push(this.InvestmentForm)
					this.Dialog.visible = false
				}
			})
		},
		// 编辑
		SaveUpdate () {
			// existing_policies_id
			this.existing_policies.map((temp, i) => {
				if (temp.existing_policies_id == this.InvestmentForm.existing_policies_id) {
					this.existing_policies.splice(i, 1, this.InvestmentForm)
				}
			})
			this.Dialog.visible = false
		},
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		this.kycAllData = this.$store.state.KYC.kycAllData
		// 若有值 则赋值
		this.existing_policies = this.kycAllData.kyc.client[this.kycClient].existing_investments_insurance.existing_policies
		this.disabled = this.$router.currentRoute.fullPath.includes('View')
	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () {

	},
}
</script>
<style lang='css' scoped>
span {
	line-height: 24px;
}
.myInput >>> .el-input__inner {
	padding-left: 350px;
}
</style>